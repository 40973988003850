<template>
    <form @submit.prevent="submitForm">
        <div v-if="showError" class="error-msg">
            <p>{{ errorMsg }}</p>
        </div>
        <div v-if="showSucces" class="succes-msg">
            <p>{{ succesMsg }}</p>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-new-password">New password</label>
                <div class="input-wrapper">
                    <input id="user-new-password" name="user-new-password" type="password" placeholder="Password"
                        v-model="newPassword" @keyup="changeInput()" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-new-password-confirm">Confirm password</label>
                <div class="input-wrapper">
                    <input id="user-new-password-confirm" name="user-new-password-confirm" type="password"
                        placeholder="Password" v-model="newPasswordAgain" @keyup="changeInput()" />
                </div>
            </span>
        </div>
        <div class="button-wrapper">
            <button class="button" data-txt="Submit">Submit</button>
        </div>
    </form>
</template>

<script>
import {
    getTempToken,
    resetPassword,
    clearData,
} from "@streampac.io/chef_sp_1";

export default {
    inject: ["isAuthenticated", "setAuthenticated"],
    props: ["passwordForgotId"],
    data() {
        return {
            newPassword: "",
            newPasswordAgain: "",
            savedToken: "",
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",
        };
    },
    created() {
        getTempToken((token) => {
            this.savedToken = token;
        });
    },
    methods: {
        submitForm() {
            if (
                this.newPassword === this.newPasswordAgain &&
                this.newPassword !== ""
            ) {
                const postObj =
                    "?passwordForgotId=" +
                    this.passwordForgotId +
                    "&password=" +
                    encodeURIComponent(this.newPassword);

                resetPassword(this.savedToken, postObj).then((response) => {
                    console.log(response);
                    if (response.succes && response.resultCode === 1000) {
                        this.showSucces = true;
                        this.showError = false;
                        this.succesMsg = "Your password has been changed";

                        setTimeout(() => {
                            clearData();
                            this.setAuthenticated(false);
                            this.$store.commit(
                                "storeRemoveAuthenticatedStatus"
                            );
                            this.$router.replace({ name: "Home" });
                        }, 2000);
                    } else if (response.resultCode === 2001) {
                        this.showError = true;
                        this.showSucces = false;
                        this.errorMsg = "This reset password link is invalid.";
                    } else {
                        this.showError = true;
                        this.errorMsg = "Something went wrong..";
                    }
                });
            } else {
                this.showError = true;
                this.errorMsg = "Your passwords do not match";
            }
        },
        changeInput() {
            this.showError = false;
            this.errorMsg = "";
            this.showSucces = false;
            this.succesMsg = "";
        },
    },
};
</script>
