<template>
    <!-- <section class="section no-header">
        <div class="container">
            <div class="column-wrapper">
                <div class="column intro-column">
                    <h1 class="block-title">
                        Reset password
                    </h1>
                    <span class="block-subtitle">Reset your password with the form below.</span>
                </div>
            </div>
        </div>
    </section> -->

    <HeroPage v-if="pageData" :data="pageData" heroType="center" />


    <section class="sign-up-section">
        <div class="inner-wrapper">
            <div class="container">
                <div class="column-wrapper fullwidth">
                    <div class="column form">
                        <p>
                            <em>If to much time has passed since you received
                                your reset e-mail, you will have to get a new
                                reset code.</em>
                        </p>

                        <ResetPasswordForm :passwordForgotId="passwordForgotId" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// @import component
import HeroPage from "@/components/HeroPage";

import ResetPasswordForm from "@/components/Forms/ResetPasswordForm";

export default {
    inject: ["isAuthenticated"],
    data() {
        return {
            data: null,
            passwordForgotId: this.$route.params.passwordForgotId,
            pageData: {
                headerTitle: 'Reset password',
                headerSubtitle: 'Reset your password with the form below'
            }
        };
    },
    components: { ResetPasswordForm, HeroPage },
    methods: {},
};
</script>
